
































import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { CmsBlock, useApi, useConfig, useContent } from '~/composables';
import HTMLContent from '~/components/solepro/HTMLContent.vue';

import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { Logger } from '~/helpers/logger';
import { GET_PRODOTTI_CONFIG } from '~/modules/storeConfig/queries/getStoreCategoriesConfig.gql';

export default defineComponent({
    metaInfo: {
        title: 'Carrello'
    },
    components: {
        HTMLContent
    },
    setup() {
        const { loadBlocks } = useContent();
        const { config: storeConfig } = useConfig()
        const editorialBlock = ref<CmsBlock | null>(null);
        const { query } = useApi();
        const allCategories = ref([]);
        const categoryStore = useCategoryStore()
        const linksList = ref([])
        useFetch(async () => {
            const editorialBlockResp = await loadBlocks({ identifiers: ["solepro_empty_cart"] });
            editorialBlock.value = editorialBlockResp[0];
            const { data } = await query(GET_PRODOTTI_CONFIG);
            const prodottiCategoryId = data['storeConfig'].sole_professionisti_product_type_category;
            categoryStore.load();
            allCategories.value = categoryStore.rawCategories?.items[0].children
            try {
                linksList.value = allCategories.value.find(el => el.id == prodottiCategoryId).children
            } catch (e) { Logger.error('EmptyCart.vue | Mh. Something went wrong searching product type category') };

        })
        return {
            editorialBlock,
            linksList,
            storeCode: storeConfig.value.store_code
        }

    }
})
